// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { FIFTEEN_DAYS, MONTHLY, ONE_DAY, TEN_DAYS, WEEKLY_MONDAY, WEEKLY_SUNDAY } from 'src/_constants';
import { ABILITY_DAIRY_SETTING, PERMISSION_UPDATE } from '../../../_constants/permissions';
import { updateDairy } from '../../../actions/dairy';
import { HasPermission } from '../../common/AccessPermission';
import { checkFeatureInSubscription } from '../../common/FeatureFlag/FeatureFlag';
import SimpleValidator from '../../common/SimpleValidator';
import withLanguage, { t } from '../../language/LanguageController';
import analytics from '../../library/firebase/analytics';
import request from '../../library/request';
import { errorHandler } from '../../library/response';
import { loader, toast } from "../../library/util";
import { DefaultLayout } from '../common/Layout';
import AddMilkRateEditableSetting from './Settings/AddMilkRateEditableSetting';
import BillAmountSetting from './Settings/BillAmountSetting';
import SelectCustomerOnMilkEntrySetting from "./Settings/SelectCustomerOnMilkEntrySetting";

class Setting extends React.Component {

    constructor(props) {
        super(props);
        this.dairySubmitHandler = this.dairySubmitHandler.bind(this);
        this.state = {
            old_dairy_type: this.props.dairy.type,
            new_dairy_type: this.props.dairy.type,
            name: this.props.dairy.name,
            billing_cycle: this.props.dairy.billing_cycle,
            isSaving: false
        };
        this.validator = new SimpleValidator();
    }

    componentDidMount() {
        analytics.setScreenName("UpdateDairy");
    }

    handleInput = e => {
        let target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        if (name === "billing_cycle" && value != "monthly") {
            if (!checkFeatureInSubscription("billing_cycle")) {
                return false;
            }
        }
        this.setState({
            [name]: value
        });
    }

    async dairySubmitHandler(e) {
        e.preventDefault();
        try {
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                this.forceUpdate(); 
                return false;
            }
            analytics.logEvent("updateDairy");
            loader.show(t("text_saving"));
            this.setState({
                isSaving: true
            });
            const formData = new FormData(document.getElementById("dairyUpdateForm"));
            const { status, data, message } = await request.post('/dairies/' + this.props.dairyId, {
                withAuth: true,
                body: formData
            });
            if (status === true) {
                this.props.updateDairy(data);
                toast(message);
            } else {
                throw message
            }
        } catch (error) {
            errorHandler(error);
        } finally {
            loader.hide();
            this.setState({
                isSaving: false
            });
        }
    }

    render() {
        const { t } = this.props;
        const { isSaving } = this.state;
        return (
            <DefaultLayout
                back
                bottomGutter={false}
                title={t("text_dairy_settings")}
                toolbar={false}>
                <form
                    id="dairyUpdateForm"
                    onSubmit={this.dairySubmitHandler}
                    encType="multipart/form-data">
                    <div className="t-card shadow-sm">
                        <div className="form-group">
                            <label htmlFor="billing_cycle">
                                {t("text_dairy_billing_cycle")}
                            </label>
                            <select
                                className="form-control"
                                name="billing_cycle"
                                id="billing_cycle"
                                value={this.state.billing_cycle}
                                onChange={this.handleInput}>
                                <option value= {MONTHLY}>{t("text_monthly")}</option>
                                <option value= {FIFTEEN_DAYS}>15 {t("text_days")}</option>
                                <option value= {TEN_DAYS} >10  {t("text_days")}</option>
                                <option value={WEEKLY_SUNDAY}>07 {t("text_days")} ({t("text_sunday_to_saturday")})</option>
                                <option value={WEEKLY_MONDAY}>07 {t("text_days")} ({t("text_monday_to_sunday")})</option>
                                <option value={ONE_DAY}>01 {t("text_day")}</option>
                            </select>
                        </div>
                        <BillAmountSetting />
                        <AddMilkRateEditableSetting/>
                        <SelectCustomerOnMilkEntrySetting />
                        <div className="form-group">
                            <label htmlFor="dairy_name">
                                {t("text_name")}
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                value={this.state.name}
                                onChange={this.handleInput}
                                name="name"
                                id="dairy_name" />
                            {this.validator.message("dairy_name", this.state.name, "required")}
                        </div>
                        <div className="form-group">
                            <label htmlFor="dairy_description">{t("text_description")}</label>
                            <textarea
                                className="form-control"
                                defaultValue={this.props.dairy.description}
                                name="description"
                                id="dairy_description" />
                        </div>
                        <HasPermission ability={ABILITY_DAIRY_SETTING} access={PERMISSION_UPDATE}>
                            <div className="form-group text-center mb-0">
                                <button
                                    className="btn btn-success btn-block"
                                    disabled={isSaving}>
                                    {isSaving ? t("text_saving") : t("text_save")}
                                </button>
                            </div>
                        </HasPermission>
                    </div>
                </form>
            </DefaultLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        settings: state.dairySettings.settings,
        dairyId: state.dairy.dairy.id,
        dairy: state.dairy.dairy
    }
};

const mapDispatchToProps = {
    updateDairy
}

export default connect(mapStateToProps, mapDispatchToProps)(withLanguage(Setting));
